jQuery(document).ready(function(){

    jQuery(document).on('click','.js-toggle-menu', function(e) {
        e.preventDefault();
        jQuery(this).toggleClass('is-open');
        jQuery('.js-site-header .js-nav__container').toggleClass('is-open').css({opacity: '1'});
        jQuery('.js-container-partial--my-garage, .js-container-partial--subscription, .js-container-partial--help').css('display', 'none');
        toggleOverlay();
    }); 

    jQuery(document).on('click','.js-link-my-garage, .js-close-my-garage', function(e) {
            e.preventDefault();
            jQuery('.js-container-partial--subscription, .js-container-speed-entry, .js-container-partial--help').fadeOut(); 
        if(!jQuery('.js-toggle-menu').hasClass('is-open')){ 
            var el = $(this);
            var offset_my_garage = jQuery(".js-link-my-garage").offset();
            offset_my_garage.left-= 125 - el.outerWidth()/2;
            offset_my_garage.top += el.height() - $(window).scrollTop() + 6;
            console.log('here');
            jQuery('.js-container-partial--my-garage').css( { left: offset_my_garage.left, top:offset_my_garage.top }); 
            jQuery('.js-container-partial--my-garage').fadeToggle(); 
        }else{
            jQuery('.nav__container').animate({opacity:'0'}, { duration: 200, queue: false });
            jQuery('.js-container-partial--my-garage').css({display: 'inline-block', right: '-100%', opacity: '1'}).animate({right:'0%'}, { duration: 200, queue: false }); 
        }
    });

      jQuery(document).on('click','.js-link-help, .js-close-help', function(e) {
            e.preventDefault();
            jQuery('.js-container-partial--subscription, .js-container-speed-entry, .js-container-partial--my-garage').fadeOut(); 
        if(!jQuery('.js-toggle-menu').hasClass('is-open')){ 
            var offset_help = jQuery(".js-link-help").offset();
            jQuery('.js-container-partial--help').css( { left: offset_help.left }); 
            jQuery('.js-container-partial--help').fadeToggle(); 
        }else{
            jQuery('.nav__container').animate({opacity:'0'}, { duration: 200, queue: false });
            jQuery('.js-container-partial--help').css({display: 'inline-block', right: '-100%', opacity: '1'}).animate({right:'0%'}, { duration: 200, queue: false }); 
        }
    });

     jQuery(document).on('click','.js-link-speed-entry, .js-close-speed-entry', function(e) {
        event.preventDefault();
        jQuery('.js-container-partial--subscription, .js-container-partial--my-garage, .js-container-partial--help').fadeOut();
        jQuery('.js-container-speed-entry').fadeToggle();
      });

  
    var lastScroll=$(window).scrollTop();
    jQuery(document).scroll(function(e){
        if($(window).scrollTop()<lastScroll-50||$(window).scrollTop()>lastScroll+70){
            lastScroll=$(window).scrollTop();
            if(jQuery('.js-container-partial--my-garage').css('display') == 'block' ){
                jQuery('.js-container-partial--my-garage').fadeOut();
            }
            if(jQuery('.js-container-partial--help').css('display') == 'block' ){
                jQuery('.js-container-partial--help').fadeOut();
            }
            if(jQuery('.js-nav__container').hasClass('is-open') && Tools.IsMobile()){
                //jQuery('.js-nav__container, .js-toggle-menu').removeClass('is-open');
            }
            if(jQuery('.js-container-partial--subscription').css('display') == 'block' ){
                jQuery('.js-container-partial--subscription').fadeOut();
            }
        }


            
    });

    jQuery(document).on('click','.js-button-back-menu',function() {
        container_parent = jQuery(this).parent();
       jQuery('.nav__container').animate({opacity:'1', left: '0'}, { duration: 200, queue: false }); 
       container_parent .animate({right:'-100%'},  function(){
       		container_parent .css({display: 'none'});
       }); 
    });  

    jQuery(document).on('click','.js-link-subscription, .js-close-subscription', function(e) {
        e.preventDefault();
        jQuery('.js-container-partial--my-garage, .js-container-partial--help, .js-container-speed-entry').fadeOut();
        if(!jQuery('.js-toggle-menu').hasClass('is-open')){ 
            var el = jQuery(".js-link-subscription");
            var offset_subscription = el.offset();
            offset_subscription.left-= 125 - el.outerWidth()/2;
            offset_subscription.top += el.height() - $(window).scrollTop() + 6;

            jQuery('.js-container-partial--subscription').css( { left: offset_subscription.left, top:offset_subscription.top }); 
            jQuery('.js-container-partial--subscription').fadeToggle();
        }else{
            jQuery('.nav__container').animate({opacity:'0'}, { duration: 200, queue: false });
            jQuery('.js-container-partial--subscription').css({display: 'inline-block', right: '-100%', opacity: '1'}).animate({right:'0%'}, { duration: 200, queue: false }); 
        }

    });


    jQuery('body').click(function(event) {
        if (!Tools.IsMobile()) {
            var target = jQuery(event.target);
             if (!target.parents(".js-container-partial--subscription").length && !target.parents(".js-nav__container").length && !target.parents(".js-container-partial--help").length  && !target.parents(".js-container-speed-entry").length && !target.parents(".js-container-partial--my-garage").length) {
                   jQuery('.js-container-partial--my-garage, .js-container-partial--help, .js-container-speed-entry, .js-container-partial--subscription').fadeOut();
            }
        }
    });


    // Hide Header on on scroll down
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = jQuery('.js-site-header').outerHeight();

    jQuery(window).scroll(function(event){
     
        didScroll = true;
    });
    
    setInterval(function() {

        if (didScroll && jQuery(window).width() < 768 && Tools.IsMobile()) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);


    function hasScrolled() {
        var st = jQuery(this).scrollTop();
        
        // Make sure they scroll more than delta
        if(Math.abs(lastScrollTop - st) <= delta)
            return;
        
        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            jQuery('.js-site-header').removeClass('nav-down').addClass('nav-up');
        } else {
            // Scroll Up
            if(st + jQuery(window).height() < jQuery(document).height()) {
                jQuery('.js-site-header').removeClass('nav-up').addClass('nav-down');
            }
        }
        
        lastScrollTop = st;
    }


    function toggleOverlay() { 
        if (jQuery('.js-toggle-menu').hasClass('is-open')) {
            jQuery('.js-mobile-overlay').fadeIn();
            document.ScrollContoller.disableScroll();
        } else {
            jQuery('.js-mobile-overlay').fadeOut(); 
            document.ScrollContoller.enableScroll()
        }
    }
});

document.ScrollContoller = new function(){
    function preventDefault(e) {
        e = e || window.event;
        if (e.preventDefault)
            e.preventDefault();
        e.returnValue = false;  
      }
      
      function preventDefaultForScrollKeys(e) {
          if (keys[e.keyCode]) {
              preventDefault(e);
              return false;
          }
      }

    this.disableScroll = function(){
        var html = document.querySelector('html');
        html.style.height = '100%';
        html.style.overflow = 'hidden'
        //document.body
    }
    this.enableScroll = function(){
        var html = document.querySelector('html');
        html.style.height = '';
        html.style.overflow = ''
        //document.body
    }

    this.disableMouseWheel = function() {
        if (window.addEventListener) // older FF
            window.addEventListener('DOMMouseScroll', preventDefault, false);
        document.addEventListener('wheel', preventDefault, {passive: false}); // Disable scrolling in Chrome
        window.onwheel = preventDefault; // modern standard
        window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
        window.ontouchmove  = preventDefault; // mobile
        document.onkeydown  = preventDefaultForScrollKeys;
      }
      
    this.enableMouseWheel = function() {
          if (window.removeEventListener)
              window.removeEventListener('DOMMouseScroll', preventDefault, false);
          document.removeEventListener('wheel', preventDefault, {passive: false}); // Enable scrolling in Chrome
          window.onmousewheel = document.onmousewheel = null; 
          window.onwheel = null; 
          window.ontouchmove = null;  
          document.onkeydown = null;  
    }
}


var stickyThing = new function(){
    var my_objects = [];
    var _is = 'static';
    jQuery('document').ready(function() {
        $('.js-sticky-nav').each(function(){
            my_objects.push({
                element: this,
                placeholder: $('<i class="sticky-nav-placeholder"></i>'),
                height: $(this).outerHeight(),
                left: $(this).offset().left,
                top: $(this).offset().top,
                width: $(this).outerWidth(),
            })
        });
        jQuery( window ).resize(this.update.bind(this))
        this.sticky();
        setTimeout(this.update,1000); //make sure css fully rendered
    }.bind(this));
    
    function getSreenProperty(){
        return {
            height: window.innerHeight,
            left:   jQuery(window).scrollLeft(),
            top:    jQuery(window).scrollTop(), 
            width:  window.innerWidth
        };
    }
    this.is = function(){return _is;}
    this.fixed = function(){
        if(_is != 'fixed'){
            var s_pos = getSreenProperty();
            jQuery(window).off('scroll',this.scroll);
            var s_h = 0;
            for(k in my_objects){
                if( my_objects[k].element.dataset['minWidth'] == undefined || parseInt(my_objects[k].element.dataset['minWidth'])<s_pos.width){
                    jQuery(my_objects[k].element).addClass('sticky-nav').css({top: s_h+'px'}).after(my_objects[k].placeholder.height(my_objects[k].height));
                    s_h+=  my_objects[k].height;
                }
                else{
                    jQuery(my_objects[k].element).removeClass('sticky-nav').css({top:''});
                    my_objects[k].placeholder.remove();
                }
            }
            _is = 'fixed';
        }
    }
    this.sticky = function(){
        if(_is != 'sticky'){
            jQuery(window).scroll(this.scroll);
            jQuery(window).trigger('scroll');
            _is = 'sticky';
        }
    }
    this.static = function(){
        if(_is != 'static'){
            jQuery(window).off('scroll',this.scroll);
            for(k in my_objects){
                jQuery(my_objects[k].element).removeClass('sticky-nav').css({top:''});
                my_objects[k].placeholder.remove();
            }
            _is = 'static';
        }
    }
    this.height = function(){
        var s_h = 0;
        for(k in my_objects)
            if( jQuery(my_objects[k].element).hasClass('sticky-nav'))
                s_h+=  my_objects[k].height;
        return s_h;
    }
    this.update = function(){
        jQuery('.js-sticky-nav').removeClass('sticky-nav');
        jQuery('.sticky-nav-placeholder').remove();
        for(k in my_objects){
            my_objects[k].height= jQuery(my_objects[k].element).outerHeight();
            my_objects[k].left  = jQuery(my_objects[k].element).offset().left;
            my_objects[k].top   = jQuery(my_objects[k].element).offset().top;
            my_objects[k].width = jQuery(my_objects[k].element).outerWidth();
        }
        jQuery(window).trigger('scroll');
    };
    this.scroll = function(){
        var s_pos = getSreenProperty();
        var s_h = 0;
        for(k in my_objects){
            if( my_objects[k].top < s_pos.top+s_h && (my_objects[k].element.dataset['minWidth'] == undefined || parseInt(my_objects[k].element.dataset['minWidth'])<s_pos.width) ){
                jQuery(my_objects[k].element).addClass('sticky-nav').css({top: s_h+'px'}).after(my_objects[k].placeholder.height(my_objects[k].height));
                s_h+=  my_objects[k].height;
            }
            else{
                jQuery(my_objects[k].element).removeClass('sticky-nav').css({top:''});
                my_objects[k].placeholder.remove();
            }
        }
    };
};

