jQuery('document').ready(function() {
    jQuery('.js-lock-form-on-submit').on('submit',function(e){
        $(this).find(':submit').attr("disabled", "disabled");
        $(this).find(':submit .fa').removeClass('hide');
    })
    $('input[name="cart_checkout[profile][create_account]"]').on('change',function(){
        console.log('')
        if($(this).is(':checked')){
            $('[data-container=change_password]').show();
        }
        else{
            $('[data-container=change_password]').hide();
        }
    });
    updateCartShippingFee();
    $('select.js-chechout-address').on('change',function(){
        var data = $(this).find('option:selected').data();
        var form_group = $(this).closest('.form-group');
        for(key in data){
            form_group.find('input[name*="['+key+']"]').val(data[key]);
        }
        updateCartShippingFee();
    })
     jQuery('.js-cart').on('change', '.js-product-quantity', function(){
        var total = 0;
        var qty_ori = jQuery(this).siblings('.js-product-quantity-ori').val(); 
        var qty_update = jQuery(this).val();
        var lang = jQuery('html').attr('lang');
        var unitprice = jQuery(this).parent().parent().parent().data('unitprice');
        var subtotal = (unitprice * qty_update).toFixed(2);
        var $this = jQuery(this);

        $this.parent().parent().parent().data('quantity', qty_update);
        $this.parent().parent().siblings('.js-item-total').data('subtotal', subtotal).html(subtotal + '&nbsp;<sup>CAD</sup>');
        
        jQuery('.js-item').each(function() {
            sub = jQuery(this).find('.js-item-total').data('subtotal');
            total = +total + +sub;
        });
        jQuery('.js-amount').html(total.toFixed(2) + '&nbsp;<sup>CAD</sup>');
       
        
        var productcode = $this.parent().parent().parent().data('partnumber');
        var linecode = $this.parent().parent().parent().data('linecode');
        var year = $this.parent().parent().parent().data('year');
        var make = $this.parent().parent().parent().data('make');
        var model = $this.parent().parent().parent().data('model');
        var id = $this.parent().parent().parent().data('id');
        var manufacturer = $this.parent().parent().parent().data('manufacturer');
        var description = $this.parent().parent().parent().find('.js-item-description').html();
        var parttype = $this.parent().parent().parent().data('parttype');
        $this.addClass('hide');
        $this.siblings(".fa").removeClass('hide');
        var url = jQuery('.js-cart .js-delete-item-url').val();

            
            var item = jQuery(this).closest('.js-item');
            var quantity = item.find('.js-product-quantity').val();

            $.ajax({
                url:url,
                dataType: 'JSON',
                type: 'POST',
                data: {
                    quantity : qty_ori,
                    productcode : productcode,
                    linecode : linecode,
                    year : year,
                    make : make,
                    model : model,
                    manufacturer : manufacturer,
                    description : description,
                    parttype : parttype,
                    id : id
                },
                success: function(data) {
                    if (data.error) {
                       
                    } else {
                       
                       $.ajax({
                            url: '/ajax/addtocart/',
                            dataType: 'JSON',
                            type: 'POST',
                            data: {
                                quantity : qty_update,
                                productcode : productcode,
                                linecode : linecode,
                                year : year,
                                make : make,
                                model : model,
                                manufacturer : manufacturer,
                                description : description,
                                parttype : parttype
                            },
                            success: function() {
                                $this.removeClass('hide');
                                $this.siblings(".fa").addClass('hide');
                                $this.siblings(".js-product-quantity-ori").val(qty_update);

                            }
                        }); 
                        
                    }
                       
                }
            });


        
     });

    jQuery('.js-cart').on('click', '.js-delete-item', function() {
        var url = jQuery('.js-cart .js-delete-item-url').val();
        var item = jQuery(this).closest('.js-item');
        var category = item.closest('.js-item-category');
        var total = jQuery('.js-cart').find('.js-grand-total');
        var buttons = jQuery('.js-cart').find('.js-action-buttons');
        var no_item = jQuery('.js-no-items');
        var loadingIcon = item.find('.js-loading-icon');
        var id = item.attr('data-id');
        var year = item.data('year');
        var make = item.data('make');
        var model = item.data('model');
        var manufacturer = item.find('.js-item-manufacturer').html();
        var description = item.find('.js-item-description').html();
        var parttype = item.data('parttype');
        var quantity = item.find('.js-product-quantity').val();
        var productcode = item.data('partnumber');
        var linecode = item.data('linecode');
        
        loadingIcon.removeClass('hide'); 
        jQuery(this).addClass('hide');
        
        $.ajax({
            url:url,
            dataType: 'JSON',
            type: 'POST',
            data: {
                quantity : quantity,
                productcode : productcode,
                linecode : linecode,
                year : year,
                make : make,
                model : model,
                manufacturer : manufacturer,
                description : description,
                parttype : parttype,
                id : id
            },
            success: function(data) {
                if (data.error) {
                    loadingIcon.addClass('hide');
                } else {
                    $.when(item.remove()).then(updateCartNumbers());
                    
                    if (category.find('.js-item').length == 0) {
                        category.remove();
                        total.remove();
                    }
                    if (jQuery('.js-cart').find('.js-item').length == 0) {
                        buttons.remove();
                    }
                     if (jQuery('.cart-listing').find('.js-item').length == 0) {
                        no_item.removeClass('hide');
                     }
                    
                    
                }
                   
            }
        });
    });

     jQuery('.js-cart').on('click', '.js-btn-empty-cart', function() {
        var category = jQuery('.js-item-category');
        var total = jQuery('.js-cart').find('.js-grand-total');
        var buttons = jQuery('.js-cart').find('.js-action-buttons');
        var no_item = jQuery('.js-no-items');
        $.ajax({
            url:'/ajax/cartPurge', 
          
            success: function(data) {
               category.find('.js-item').remove();
                category.remove();
                total.remove();
                buttons.remove(); 
                no_item.removeClass('hide');
                jQuery('.js-menu-cart-count, .js-breadcrumb-cart-count').html('');
               jQuery('.js-breadcrumb-cart-text-single, .js-breadcrumb-cart-text-multiple').addClass('hide');
                   
            }
        });
     });
    jQuery('.js-delivery-method input').on('change', function() {
        updateCartShippingFee();
        if (jQuery('.js-delivery-method input:checked').val() == 'ship') {
            jQuery('.js-shipping-address').removeClass('hide');
            jQuery('.js-store-pick-up').fadeOut(); 
        } else if(jQuery('.js-delivery-method input:checked').val() == 'pickup') {
            jQuery('.js-shipping-address').addClass('hide');
            jQuery('.js-store-pick-up').fadeIn();
        }
        else {
            jQuery('.js-shipping-address').addClass('hide');
            jQuery('.js-store-pick-up').fadeOut();
        }
    });

    jQuery('.js-cart-checkout-shipping-address').on('change', function() {
        updateCartShippingFee();
    }).trigger('change')

    if (jQuery('.js-billing-address').length){
        updateCartTotals();
        jQuery('.js-billing-address').on('change', function() {
            updateCartTotals();
        });
    }
});

/**
 * Updates the numbers in the page related to the cart items
 */
function updateCartNumbers() {
    var count = 0;
    
    //Grand total calculation
    var total = 0;
    jQuery('.js-item').each(function() {
        total += parseFloat(jQuery(this).attr('data-unitprice')) * parseInt(jQuery(this).attr('data-quantity'))
        count++;
    });
    
    jQuery('.js-grand-total .js-amount').html('$ ' + total.formatMoney(2, '.', ' '));
    
    //Total count in the menu
    var menuCount = jQuery('.js-menu-cart-count');
    
    if (count > 0){
        menuCount.html('(' + count + ')');
    } else {
        menuCount.html('');
    }
}

function updateCartShippingFee() {
  
    if (jQuery('.js-delivery-method input:checked').val() == 'ship') {
        var shipping_address = jQuery('.js-cart-checkout-shipping-address').val();
    } else {
        var shipping_address = jQuery('.js-delivery-method input:checked').val();
    }
   
    var shipping_price;
    var lang = jQuery('html').attr('lang');
    jQuery('.js-loader').removeClass('hide');
    if (shipping_address != 'pickup') {
        if(window.ajaxGetShipping) window.ajaxGetShipping.abort();
        window.ajaxGetShipping = $.ajax({
        url:'/ajax/getShipping',
        dataType: 'JSON',
        type: 'POST',
        data: $('form[name=cart_checkout]').serializeArray(),
            success: function(data){

                if (data == null) {
                    shipping_price = '---';
                    if (lang == 'fr') {
                        invalid_address = 'Non déterminé'; 
                    } else {
                        invalid_address = 'Non specified';
                    }
                    jQuery('.js-shipping-transporter').html(invalid_address);
                    jQuery('.js-shipping-delay, .js-discount').addClass('hide');
                    
                } else {
                    shipping_price = data.shipping_total;
                    jQuery('.js-shipping-transporter').html(data.transporterName);
                    if(typeof data.shippingDate == 'string'){
                        var now = new Date()
                        var shippingDate = new Date(data.shippingDate);
                        var diff = Math.ceil((shippingDate - now) / (1000 * 60 * 60 * 24))
                        jQuery('.js-shipping-delay').html(jQuery('.js-shipping-delay').data('text').replace('%s',diff+1) )
                        jQuery('.js-shipping-delay, .js-discount').removeClass('hide');
                    }
                    jQuery('.js-btn-checkout').prop('disabled', false);

                }
                updateCartSubTotal(shipping_price, shipping_address);
                jQuery('.js-pick-up').addClass('hide');
            }
        });
    } else {
        shipping_price = 0;
        jQuery('.js-shipping-transporter').html('');
        jQuery('.js-shipping-delay, .js-discount').addClass('hide');
        
       
        updateCartSubTotal(shipping_price, shipping_address);
        jQuery('.js-btn-checkout').prop('disabled', false);  
        jQuery('.js-pick-up').removeClass('hide');
    }
   
}

function updateCartSubTotal(shipping_price, shipping_address)
{
    
    console.log(shipping_price);
    var items_subtotal = 0;
    var shipping_flat_fee = 0;
    var lang = jQuery('html').attr('lang');
    var discount = jQuery('.js-input-shipping-rebate').val();
    var shipping_final_price = shipping_price.toFixed(2);
       
     if (lang == 'fr') {
            var shipping_free = 'Livraison gratuite.';
            var rebate = 'Rabais';
            discount_dollar_sign =  discount + ' $';
            shipping_price_dollar_sign = parseFloat(shipping_price).toFixed(2) + ' $'; 
        } else {
            var shipping_free = 'Ships this order free of charge.';
            var rebate = 'Discount';
            discount_dollar_sign = '$ ' + discount;
            shipping_price_dollar_sign = '$ ' + parseFloat(shipping_price).toFixed(2); 
        }
    
    jQuery('.js-input-shipping-price').val(shipping_final_price);
    jQuery('.js-shipping-final-price').html(shipping_final_price);
    jQuery('.js-discount').html(shipping_price?'':shipping_free/*'<span class="js-shipping-price"></span> - ( <span class="js-shipping-rebate">' + rebate + ' ' + discount_dollar_sign +'</span> )'*/);
    jQuery('.js-shipping-price').html(shipping_price_dollar_sign);
    
    jQuery('.js-item-subtotal').each(function() {
        items_subtotal += parseFloat(jQuery(this).text());
    });

     jQuery('.js-item-deposit').each(function() {
        items_subtotal += parseFloat(jQuery(this).text()); 
    });

       jQuery('.js-item-tax').each(function() {
        items_subtotal += parseFloat(jQuery(this).text()); 
    });

     

    var subtotal =  isNaN(shipping_price) ? parseFloat(items_subtotal).toFixed(2) :  parseFloat(+shipping_final_price + +items_subtotal).toFixed(2);
    jQuery('.js-subtotal').attr('data-js-subtotal', subtotal);  
    jQuery('.js-subtotal').data('js-subtotal', subtotal);
    //subtotal = subtotal;
    jQuery('.js-subtotal-amount').html(subtotal); 
    updateCartTotals();
}

function updateCartTotals()
{

    var province =$('#cart_checkout_billing_address_state').val();
    if (province != undefined) {
        var subtotal = jQuery('[data-js-subtotal]').data('js-subtotal');

    $.ajax({
        url:'/ajax/getTaxeByProvince',
        dataType: 'JSON',
        type: 'POST',
        data: {
            'province' : province
        },
        success: function(data){
            
            subtotal = subtotal.toString();
            subtotal = subtotal.replace(' ','');
            var tps = subtotal * data.tps;
            var tvp = parseFloat(subtotal)  * parseFloat(data.tvp);
            var total = parseFloat(subtotal) + parseFloat(tps) + parseFloat(tvp);
            var lang = jQuery('html').attr('lang');
            tps =  tps.toFixed(2);
            tvp =  tvp.toFixed(2);
            total = total.toFixed(2);
            jQuery('.js-input-tvp').val(tvp);
            jQuery('.js-input-tps').val(tps);
           
            jQuery('.js-input-subtotal-amount').val(subtotal);
                jQuery('.js-tps').html(tps+'&nbsp;<sup>CAD</sup>');
                jQuery('.js-tvp').html(tvp+'&nbsp;<sup>CAD</sup>');
                jQuery('.js-total').html(total+'&nbsp;<sup>CAD</sup>');
            jQuery('.js-loader').addClass('hide');
        },
        error:function(){
            jQuery('.js-input-tvp').val('');
            jQuery('.js-input-tps').val('');
            jQuery('.js-tps').html('&nbsp;');
            jQuery('.js-tvp').html('&nbsp;');
            jQuery('.js-total').html('&nbsp;');
            jQuery('.js-loader').addClass('hide');
        }
    });
    }else {
        jQuery('.js-loader').addClass('hide');
    }
    
}