BuyersGuide = function(container){
    if(!(this instanceof BuyersGuide))
        throw new Error("Uncaught TypeError: BuyersGuide is a construtor.");
    if(container == undefined){
        container = $('#container-buyers-guide');
    }
    var _ = this;
    var histy = [];

    this.get = function(manufacturer, parttype, partnumber){
        if(histy[manufacturer+'-'+parttype+'-'+partnumber]){
            var table = tableCreate(histy[manufacturer+'-'+parttype+'-'+partnumber]);
            $(".container-buyers-guide").html(table);
        }
        else{
            loadInformations(manufacturer,partnumber).then(function(val){
                histy[manufacturer+'-'+parttype+'-'+partnumber] = val;
                _.get(manufacturer, parttype, partnumber);
            });
        }
    }

    function loadInformations(manufacturer,partnumber){
        return new Promise(function(resolve, reject) {
            $.ajax({
                url: '/ajax/buyersGuide',
                type: "post",
                data:{
                    manufacturer: manufacturer,
                    partnumber: partnumber,
                },
                dataType: "json",
                beforeSend: function() {
                    container.html('<div class="text-center loader"><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i></div>');
                },
                success: function(data) {
                    resolve(data) // Resolve promise and when success
                },
                error: function(err) {
                    reject(err) // Reject the promise and go to catch()
                }
            });
        });
    }

    function FormatText(text, container){
        if(text){
            var linkText = document.createTextNode(text);
            container.appendChild(linkText);
        }
    }

    function GenerateLink(text, href, title){
        var a = document.createElement('a');
        FormatText(text,a);
        a.title = title;
        a.href = href;
        return a;
    }

    function yearsToHtml(years, make, model, container){
        var first = true;
        for(year in years){
            if(!first)
                FormatText(' - ',container);
            var link = GenerateLink(year, years[year], year+' '+ make + ' ' + model);
            container.appendChild(link);
            first = false;
        }
    }

    function engineToHtml(engines, container){
        if(engines && engines.length){
            var list = document.createElement('ul');
            for(key in engines){
                var engine = engines[key];
                var item = document.createElement('li');
                FormatText(engine.engineType,item);
                list.appendChild(item);
            }
            container.appendChild(list);
        }
    }

    function Filter(array, search){
        var index = array.indexOf(search);
        if (index > -1) array.splice(index, 1);
        return array;
    }

    function tableCreate(data) {
        // create elements <table> and a <tbody>
        var tbl = document.createElement("table");
        var tblHead = document.createElement("thead");
        var tblBody = document.createElement("tbody");
        tbl.classList.add('buyers-guide-table');

        //Set Header
        var collums = [ 'Make', 'Model','Years', 'Submodel', 'Engines', 'Position'];
        var has_submodel = false;
        var has_engine = false;
        var has_position = false;
        for (key in data) {
            var rowdata = data[key];
            if(rowdata.subModel) has_submodel = true;
            if(rowdata.engines && rowdata.engines.length) has_engine = true;
            if(rowdata.position) has_position = true;
            if(has_submodel && has_engine && has_position) break;
        }
        if (!has_position) collums = Filter(collums,"Position");
        if (!has_engine) collums = Filter(collums,"Engines");
        if (!has_submodel) collums = Filter(collums,"Submodel");

        var hrow = document.createElement("tr");
        tblHead.appendChild(hrow);
        collums.forEach(function(columnName){
            var hcell = document.createElement("th");
            hcell.classList.add('buyers-guide-column');
            hcell.classList.add('buyers-guide-column-'+columnName);
            hcell.classList.add('color--primary');
            FormatText(columnName,hcell);
            hrow.appendChild(hcell);
        });

        //SetBody
        for (key in data) {
            var rowdata = data[key];
            var row = document.createElement("tr");
            collums.forEach(function(columnName){
                var cell = document.createElement("td");
                cell.classList.add('buyers-guide-column');
                cell.classList.add('buyers-guide-column-'+columnName);
                switch(columnName){
                    case 'Years': 
                        yearsToHtml(rowdata.links,rowdata.make,rowdata.model, cell);
                        break;
                    case 'Make': 
                        FormatText(rowdata.make, cell);
                        break;
                    case 'Model': 
                        FormatText(rowdata.model, cell);
                        break;
                    case 'Submodel': 
                        FormatText(rowdata.subModel, cell);
                        break;
                    case 'Engines': 
                        engineToHtml(rowdata.engines,cell)
                        break;
                    case 'Position': 
                        FormatText(rowdata.position, cell);
                        break;
                }
                row.appendChild(cell);
                tblBody.appendChild(row);
            });
        }
      
        // append the <tbody> inside the <table>
        tbl.appendChild(tblHead);
        tbl.appendChild(tblBody);

        return tbl;
    }
}