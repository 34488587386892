ActivityTracker = new function(){

    function pushFBPixel(evtName, obj){
        if (typeof fbq != undefined && typeof fbq === 'function') fbq('track',evtName,obj);
    }

    function pushAnalytics(evtName, obj){
    }

    this.search = function(search ){
        pushFBPixel('Search', {
            search_string: search
        })
    }

    this.viewProducts = function(productcode,linecode,manufacturer,parttype ){
        pushFBPixel('ViewContent', {
            currency: 'CAD', 
            content_ids: [linecode+productcode],
            content_category: false?'Applicative':'Non-Applicative',
            content_name: manufacturer + ' ' + parttype,
            content_type: 'product',
        });
    }

    this.initiateCheckout = function(items, subtotal){
        ids = [];
        items.forEach(function(item){
            ids.push(item.id);
        });
        pushFBPixel('InitiateCheckout', {
            currency: 'CAD', 
            content_ids: ids,
            contents: items,
            num_items: items.length,
            value: subtotal,
        });
    }

    this.purchase = function(items,subtotal){
        ids = [];
        items.forEach(function(item){
            ids.push(item.id);
        });

        pushFBPixel('Purchase', {
            currency: 'CAD', 
            content_ids: ids,
            content_name: 'Confirmation',
            contents: items,
            num_items: items.length,
            value: subtotal,
            content_type: 'product'
        });
    }

    this.addToCart = function(quantity, unitprice,productcode,linecode,manufacturer,parttype){
        pushFBPixel('AddToCart', {
            value: quantity * unitprice, 
            currency: 'CAD', 
            content_ids: [linecode+productcode],
            content_name: manufacturer + ' ' + parttype,
            contents: [
                {
                    id: productcode,
                    quantity: quantity
                }
            ],
            content_type: 'product',
        });
    }
}