$.fn.SectionFilter = function(settings) {

    $(this).each(function(el){
        new SectionFilter(this,settings);
    });

    function SectionFilter(section, settings){
        var _ = this;
        var searchBar = $(section).find('input[type=search]')
        console.log(searchBar);

        searchBar.on('keyup',function(){_.filter($(this).val())})

        function getObjects(){
            return $(section).find('[data-filter]')
        }

        function sanitize(string){
            var val = string.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            return val.replace( /[\W_]+/gm, '-').split('-');
        }


        function match(a,b){
            if(a == '' || b == '') return true;
            var texts = sanitize(a);
            var searches = sanitize(b);
            var words = searches.length;
            var find = 0;

            while(searches.length){
                search = searches.pop();
                for(var i in texts){
                    var rate = JaroWrinker(search,texts[i]);
                    if(rate>0.8){
                        find++
                        texts.splice(i, 1);
                        break;
                    }
                    else if(search.length<=4 && texts[i].includes(search)){
                        find++;
                        break;
                    }
                }
            }
            if(find/words > 0.9) return true;
            return false;
        }

        function JaroWrinker(s1, s2) {
            var m = 0;
    
            // Exit early if either are empty.
            if ( s1.length === 0 || s2.length === 0 ) {
                return 0;
            }
    
            // Exit early if they're an exact match.
            if ( s1 === s2 ) {
                return 1;
            }
    
            var range     = (Math.floor(Math.max(s1.length, s2.length) / 2)) - 1,
                s1Matches = new Array(s1.length),
                s2Matches = new Array(s2.length);
    
            for ( i = 0; i < s1.length; i++ ) {
                var low  = (i >= range) ? i - range : 0,
                    high = (i + range <= s2.length) ? (i + range) : (s2.length - 1);
    
                for ( j = low; j <= high; j++ ) {
                if ( s1Matches[i] !== true && s2Matches[j] !== true && s1[i] === s2[j] ) {
                    ++m;
                    s1Matches[i] = s2Matches[j] = true;
                    break;
                }
                }
            }
    
            // Exit early if no matches were found.
            if ( m === 0 ) {
                return 0;
            }
    
            // Count the transpositions.
            var k = n_trans = 0;
    
            for ( i = 0; i < s1.length; i++ ) {
                if ( s1Matches[i] === true ) {
                for ( j = k; j < s2.length; j++ ) {
                    if ( s2Matches[j] === true ) {
                    k = j + 1;
                    break;
                    }
                }
    
                if ( s1[i] !== s2[j] ) {
                    ++n_trans;
                }
                }
            }
    
            var weight = (m / s1.length + m / s2.length + (m - (n_trans / 2)) / m) / 3,
                l      = 0,
                p      = 0.1;
    
            if ( weight > 0.7 ) {
                while ( s1[l] === s2[l] && l < 4 ) {
                ++l;
                }
    
                weight = weight + l * p * (1 - weight);
            }
    
            return weight;
        }

        this.filter = function(value){
            getObjects().each(function(){
                if(match($(this).data('filter'),value)) $(this).show()
                else $(this).hide()
            })
        }
    }
}

jQuery('document').ready(function(){
    $('.js-filter-section').SectionFilter();
})