function InputPassword(passwordInput, passwordVisibilityButtton, capsLockMessage){
    if(!(this instanceof InputPassword))
      throw new Error("Uncaught TypeError: InputPassword is a construtor.");
    
    function showCapsLockMessage() {
        capsLockMessage.style.display = "block";
    }
    
    function hideCapsLockMessage() {
        capsLockMessage.style.display = "none";
    }
    
    function updateCapsLockMessage(event) {
        if (event.getModifierState && event.getModifierState("CapsLock")) {
            showCapsLockMessage();
        } else {
            hideCapsLockMessage();
        }
    }
    
    function togglePasswordVisibility(event) {
        event.preventDefault()
        if (passwordInput.type === "password") {
          passwordInput.type = "text";
        } else {
          passwordInput.type = "password";
        }
      }

    passwordInput.addEventListener("focus", updateCapsLockMessage);
    passwordInput.addEventListener("keydown", updateCapsLockMessage);
    passwordInput.addEventListener("keyup", updateCapsLockMessage);
    passwordVisibilityButtton.addEventListener("click", togglePasswordVisibility)
}

jQuery( document ).ready(function() {
    var inputPass = $('.js-input-password');
    if(inputPass.length){
        new InputPassword(inputPass.find("input").get(0),inputPass.find("button").get(0),$(".js-caplock").get(0))
    }
})